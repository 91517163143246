<template>
  <Form title="اضافة خدمة" :formErrors="formErrors" :subcategories="subcategories" :cities="cities"
  :loading="loading" @saveData="submit" :edit="false" :validationRules="getValidationRules()" />
</template>

<script>
import Form from "./Form.vue"
import { required, minLength, maxLength, email, decimal, integer, numeric } from "vuelidate/lib/validators";
export default {
  name: "Create",
  components: {
    Form,
  },
  data() {
    return {
      loading: false,
      subcategories: [],
      cities: [],
      formErrors: null,
    };
  },
  created() {
    this.fetchSubcategories();
    this.fetchCities();
  },
  methods: {
    submit(data) {
      this.loading = true;

      this.$http
        .post(`/services`, data)
        .then((res) => {
          // handle success
          if (res.data.status == 202) {
            this.$router.push({ name: "Services" });
          }
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          this.loading = false;
          if (error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
    },
    fetchSubcategories() {
      this.toggleLoadSpinner();
      this.$http
        .get(`/general/subcategories`)
        .then((res) => {
          // handle success
          this.subcategories = res.data.data;
          this.toggleLoadSpinner();
        })
        .catch(() => {
          //
          this.toggleLoadSpinner();
        });
    },
    fetchCities() {
      this.toggleLoadSpinner();
      this.$http
        .get(`/general/cities`)
        .then((res) => {
          // handle success
          this.cities = res.data.data;
          this.toggleLoadSpinner();
        })
        .catch(() => {
          //
          this.toggleLoadSpinner();
        });
    },
    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    },
    getValidationRules() {
      return {
        name: { required, min: minLength(2), max: maxLength(200) },
        description: { required, min: minLength(2), max: maxLength(4000) },
        phone: { required },
        phone_2: { numeric },
        email: { email },
        address: { required, min: minLength(2), max: maxLength(300) },
        latitude: { required, decimal },
        longitude: { required, decimal },
        city_id: { required, integer },
        status: { required },
        image: { required },
        subcategories: { required },
        social_links: { },
      }
    }

  },
};
</script>
